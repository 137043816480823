import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import React from 'react';
import { Translation, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import {
  formatDate,
  formatNumber,
  formatString,
  getSorter,
  sortData,
  SortDirection,
} from '../../helpers/utils';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Defaults, Hr, px, TextEx } from '../common';
import Pagination, { PaginationProps } from '../universalComponents/Pagination';
import SpinnerContainer from '../universalComponents/SpinnerContainer';
import { MobileTableRow } from './MobileTableRow';
import Txt from './Txt';

const headBorder = `1px solid #00000010`;
const cellPadding = 'unset';
const firstPadding = '0 1.2rem 0 2.5rem';
const lastPadding = '0 2.5rem 0 1.2rem';

export interface ColumnHeader {
  id: string;
  caption?: string;
  width?;
  minWidth?: number | string;
  render?: (value: any, row: any, n: number) => React.ReactNode;
  headerIcon?: React.ReactNode;
}

export default function TableEx<T>({
  className,
  title,
  titleArgs = [],
  titleComponent,
  subHeader,
  columns,
  defaultSortBy,
  defaultSortDirection,
  rows,
  loading,
  pinnedColumns,
  onShowMore,
  mobileLayout = 'row',
  minWidth,
  left,
  top,
  sorter,
  noPadding,
  mobileBreak = '834px',
  backgroundColor = 'white',
  textColor,
  headerFontSize = '1.125rem',
  cellFontSize = '1rem',
  noSort,
  headClassName = '',
  border,
  onRowClick,
  noMobile,
  pagination,
}: {
  className?: string;
  title?: string;
  titleArgs?: any[];
  titleComponent?: React.ReactNode;
  subHeader?: React.ReactNode;
  columns: ColumnHeader[];
  defaultSortBy?: keyof T;
  defaultSortDirection?: SortDirection;
  rows: Array<{ [key in keyof T] }>;
  loading?: boolean;
  onShowMore?: () => boolean;
  minWidth?;
  mobileBreak?: string;
  mobileLayout?: 'row' | 'column';
  left?;
  noSort?: boolean;
  top?: number | string;
  sorter?: (sortDirection: SortDirection, sortBy?: keyof T) => (a, b) => number;
  noPadding?: boolean;
  backgroundColor?: string;
  pinnedColumns?: string[];
  textColor?: string;
  headerFontSize?;
  cellFontSize?: string;
  noMobile?: boolean;
  headClassName?: string;
  border?: string;
  onRowClick?: (i: number, row: any) => void;
  pagination?: PaginationProps;
}) {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = React.useState<keyof T | undefined>(defaultSortBy);
  const [sortDirection, setSortDirection] = React.useState<SortDirection>(
    defaultSortDirection || 'desc',
  );

  const isMobile = noMobile ? false : useMediaQuery(`(max-width: ${mobileBreak})`);
  const [hasNextData, setHasNextData] = React.useState(true);
  const [sortChanged, setSortChanged] = React.useState(false);

  if (!sortChanged && !!defaultSortDirection && sortDirection !== defaultSortDirection) {
    setSortDirection(defaultSortDirection);
  }

  const createSortHandler = (prop: keyof T) => (event: React.MouseEvent<unknown>) => {
    setSortChanged(true);
    handleRequestSort(event, prop);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, prop: keyof T) => {
    const isDesc = sortBy === prop && sortDirection === 'desc';
    setSortDirection(isDesc ? 'asc' : 'desc');
    setSortBy(prop);
  };

  const handleMoreData = () => {
    if (onShowMore) {
      const hasNext = onShowMore();
      setHasNextData(hasNext);
    }
  };

  return (
    <TableRoot className={className} minWidth={minWidth} left={left} top={top}>
      <Paper
        style={{
          backgroundColor,
          boxShadow: '2px 3px 32px 0px #A8B8C852',
          borderRadius: '8px',
        }}>
        {!!subHeader && subHeader}
      </Paper>
      {title && (
        <TableTitle>
          <TextEx weight={'500'} size={'1.125rem'}>
            <div
              dangerouslySetInnerHTML={{
                __html: formatString(t(title), ...titleArgs) || '',
              }}
            />
          </TextEx>
          {titleComponent}
        </TableTitle>
      )}
      <Paper
        style={{
          backgroundColor,
          boxShadow: '2px 3px 32px 0px #A8B8C852',
          borderRadius: '8px',
        }}>
        <SpinnerContainer
          loading={!!loading}
          classNameContainer={typeof loading == 'boolean' ? 'table_container' : ''}>
          <TableWrap noPadding={noPadding}>
            <Table>
              {!isMobile && (
                <TableHead
                  className={headClassName}
                  style={{
                    backgroundColor: subHeader ? Defaults.lightBlue : 'transparent',
                    borderTop: subHeader ? headBorder : '',
                    borderBottom: headBorder,
                    borderTopLeftRadius: subHeader ? '0px' : '8px',
                    borderTopRightRadius: subHeader ? '0px' : '8px',
                  }}>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell
                        key={`th-${i}`}
                        padding={'none'}
                        sortDirection={sortBy === column.id ? sortDirection : false}
                        style={{
                          padding: cellPaddingCalculate(i, columns.length - 1, noPadding),
                          height: '3.75rem',
                          minWidth: column.minWidth,
                          maxWidth: column.width,
                          border: 'unset',
                          borderLeft: i > 0 ? border : undefined,
                          borderRadius: borderRadius(i, columns.length - 1),
                        }}>
                        {column.headerIcon}
                        {column.caption && (
                          <TableSortLabel
                            active={!noSort && sortBy === column.id}
                            direction={sortDirection}
                            onClick={
                              !noSort
                                ? createSortHandler(column.id as keyof T)
                                : undefined
                            }
                            style={{
                              paddingLeft: column.headerIcon ? '10px' : undefined,
                            }}
                            hideSortIcon={noSort}
                            href={''}>
                            <TextEx color={textColor} size={headerFontSize}>
                              <Txt k={column.caption} />
                            </TextEx>
                          </TableSortLabel>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {sortData(
                  rows,
                  sorter
                    ? sorter(sortDirection, sortBy)
                    : getSorter(sortDirection, sortBy),
                ).map((row, i) =>
                  isMobile ? (
                    <MobileTableRow
                      key={i}
                      row={row}
                      rowIndex={i}
                      layout={mobileLayout}
                      columns={columns}
                      onClick={() => onRowClick?.(i, row)}
                      pinnedColumns={pinnedColumns}
                    />
                  ) : (
                    <TableRow
                      hover
                      key={i}
                      style={{
                        height: '3.75rem',
                        backgroundColor:
                          i % 2 === 0 ? Defaults.whiteColor : Defaults.aliceBlue,
                      }}
                      className={row?.['className']}
                      onClick={() => onRowClick?.(i, row)}>
                      {columns.map((column, j) => (
                        <TableCell
                          key={j}
                          padding={'none'}
                          style={{
                            padding: noPadding
                              ? cellPadding
                              : cellPaddingCalculate(j, columns.length - 1, noPadding),
                            borderBottom: 'unset',
                            border: border || 'unset',
                            fontSize: cellFontSize,
                            color: textColor,
                          }}>
                          {column.render
                            ? column.render(row[column.id], row, i)
                            : rowValueToString(row[column.id])}
                        </TableCell>
                      ))}
                    </TableRow>
                  ),
                )}
              </TableBody>
              {!!onShowMore && (
                <tfoot>
                  <tr>
                    <td colSpan={100} style={{ padding: 0 }}>
                      <Translation>
                        {(t) => (
                          <Button
                            href={undefined}
                            disabled={!hasNextData}
                            title={t('table.action.more')}
                            onClick={() => handleMoreData()}
                            style={{
                              width: '100%',
                              height: '1.875rem',
                              padding: 0,
                              backgroundColor: subHeader
                                ? Defaults.black24
                                : Defaults.whiteColor,
                              color: Defaults.grayColor,
                              borderRadius: Defaults.borderRadius,
                              fontSize: '1.125rem',
                            }}>
                            &bull;&nbsp;&bull;&nbsp;&bull;
                          </Button>
                        )}
                      </Translation>
                    </td>
                  </tr>
                </tfoot>
              )}
            </Table>
            {pagination && (
              <Pagination {...pagination} rows={rows} mobileBreak={mobileBreak} />
            )}
          </TableWrap>
        </SpinnerContainer>
      </Paper>
    </TableRoot>
  );
}

const TableRoot = styled.div`
  width: 100%;
  min-width: ${(props) => px(props.minWidth)};

  @media (max-width: 834px) {
    border-radius: 8px;
  }

  ${(props) => (props.top ? `margin-top: ${px(props.top)};` : '')}
  ${(props) => (props.left ? `margin-left: ${px(props.left)};` : '')}
`;
const TableTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 2.1rem;

  @media (max-width: 576px) {
    padding: 1rem 1.4rem;
    flex-wrap: wrap;
    font-size: 1rem;
  }
`;
const TableWrap = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 2rem;
`;

function borderRadius(i, last): string | undefined {
  switch (i) {
    case 0:
      return '0.3125rem 0 0 0.3125rem';
    case last:
      return '0 0.3125rem 0.3125rem 0';
    default:
      return;
  }
}

const cellPaddingCalculate = (i, last, noPadding): string | undefined => {
  if (noPadding) {
    return cellPadding;
  }

  switch (i) {
    case 0:
      return firstPadding;
    case last:
      return lastPadding;
    default:
      return cellPadding;
  }
};

export function rowValueToString(value?: any): string {
  if (value) {
    switch (typeof value) {
      case 'number':
        return formatNumber(value);
      case 'object':
        return value instanceof Date ? formatDate(value) : value.toString();
      default:
        return value.toString();
    }
  }
  return '';
}
