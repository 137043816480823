import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { t } from '../../config/i18n';
import { ISearchData } from '../../domain/services/GeneralServiceEntities';
import GeneralService from '../../services/GeneralService';
import { classNames } from '../../utils/api';
import { ArrowRightIcon, Search } from '../common';
import InputEx from '../controls/InputEx';

const emprySearch = {
  deal: undefined,
  lot: undefined,
  user: undefined,
};

export const SearchComponent = ({ className }: { className?: string }) => {
  const [search, setSearch] = useState('');
  const [value, setValue] = useState<ISearchData>(emprySearch);

  const debouncedSearch = useCallback(
    debounce((params) => GeneralService.search(params).then(setValue), 500, {
      trailing: true,
    }),
    [],
  );

  const valueIsFound = !!value.deal || !!value.lot || !!value.user;

  useEffect(() => {
    setValue(emprySearch);
    if (search.length >= 5 && search.length <= 12) debouncedSearch({ search });
  }, [search]);

  return (
    <div className={classNames('search-component-container', className)}>
      <span className={'hint'}>{t('top-bar.search-enter-id')}</span>
      <InputEx
        borderColor={'transparent'}
        width={'100%'}
        hint={t('top-bar.search-placeholder')}
        value={search}
        autoFocus
        onChange={setSearch}
        type={'text'}
      />
      <Search />
      {valueIsFound && (
        <div className="search-results-container">
          {!!value.deal && <SearchResult type="deal" value={value} />}
          {!!value.lot && <SearchResult type="lot" value={value} />}
          {!!value.user && <SearchResult type="user" value={value} />}
        </div>
      )}
    </div>
  );
};

const SearchResult = ({
  type,
  value,
}: {
  type: 'deal' | 'user' | 'lot';
  value: ISearchData;
}) => {
  const url = useMemo(() => {
    if (type === 'lot') {
      return value.lot?.is_owner
        ? `/bids/${value.lot?.id}`
        : `/deals/bid$${value.lot?.id}`;
    }
    return `/${type}s/${value[type]}`;
  }, [value, type]);

  return (
    <a className="search-result" href={url}>
      <span className={'search-result-hint'}>{t(`top-bar.search-${type}`)}</span>
      <a className="search-result-link">{type === 'lot' ? value.lot?.id : value[type]}</a>
      <ArrowRightIcon />
    </a>
  );
};
