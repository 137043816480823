import { IErrorCallbackType, ISuccessCallbackType } from '../domain/services/common';
import { ISearchData, ISearchParams } from '../domain/services/GeneralServiceEntities';
import { generalRequest } from '../utils/axiosSetup';

export default abstract class GeneralService {
  // OK
  static search = async (
    params: ISearchParams,
    onSuccess?: ISuccessCallbackType<ISearchData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<ISearchData>({
      url: '/search',
      params: { search_text: params.search },
      successCallback: onSuccess,
      errorCallback: onError,
    });
}
