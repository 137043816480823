import { Label } from 'recharts';

import { HistoryFilters } from '../components/history';
import { Operation } from '../components/operations/OperationsList';
import { MerchantFilters } from '../components/skypay';
import { Operation as MerchantOperation } from '../components/skypay/OperationsList';
import { IErrorCallbackType, ISuccessCallbackType } from '../domain/services/common';
import { ICommonParams } from '../domain/services/common';
import {
  IAutorequisitesActivateData,
  IDealsLastRequisitesData,
  IDealsLastRequisitesParams,
  IFastDealNotificationsData,
  IFastDealNotificationsParams,
  IFastDealReadNotificationsData,
  IFastDealReadNotificationsParams,
  IPaymentOperationsExportData,
  IPaymentOperationsExportParams,
  ISkyPayOperationsData,
  ISkyPayOperationsExportData,
  ISkyPayOperationsExportParams,
  ISkyPayOperationsParams,
  ITradeActivateData,
  ITradeActivateParams,
  IUserAllNotificationsData,
  IUserAllNotificationsParams,
  IUserBalanceData,
  IUserDealsExportData,
  IUserDealsExportParams,
  IUserNotificationsData,
  IUserNotificationsParams,
  IUserOperationsData,
  IUserOperationsExportData,
  IUserOperationsExportParams,
  IUserOperationsParams,
  IUserReadNotificationsData,
  IUserReadNotificationsParams,
  IUserReferralsData,
} from '../domain/services/OperationsServiceEntities';
import { getAuthHeader } from '../helpers/authHeader';
import { getUTCTime, parseDate, toLowerCase, toUpperCase } from '../helpers/utils';
import { generalRequest } from '../utils/axiosSetup';
import { operationTypes } from '../utils/types';

export default abstract class OperationsService {
  // OK
  static tradeSellActivate = async (
    params: ITradeActivateParams,
    onSuccess?: ISuccessCallbackType<ITradeActivateData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<ITradeActivateData>({
      url: '/operations/trading-status',
      method: 'PATCH',
      data: { is_active_sell: params.activate },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static tradeBuyActivate = async (
    params: ITradeActivateParams,
    onSuccess?: ISuccessCallbackType<ITradeActivateData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<ITradeActivateData>({
      url: '/operations/trading-status',
      method: 'PATCH',
      data: { is_active_buy: params.activate },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static autorequisitesActivate = async (
    params: ITradeActivateParams,
    onSuccess?: ISuccessCallbackType<IAutorequisitesActivateData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IAutorequisitesActivateData>({
      url: '/operations/autorequisite-status',
      method: 'PATCH',
      data: { is_active: params.activate },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static balance = async (
    onSuccess?: ISuccessCallbackType<IUserBalanceData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IUserBalanceData>({
      url: '/operations/total-balance',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static operations = async (
    params: IUserOperationsParams,
    onSuccess?: ISuccessCallbackType<IUserOperationsData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IUserOperationsData>({
      url: '/operations',
      params: {
        _from: params.from,
        _to: params.to,
        symbol: params.symbol,
        deal_state: params.deal_state,
        page: params.page,
        action: params.type ? operationTypes[params.type] : undefined,
        offset: params.offset,
        limit: params.limit,
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static walletOperations = async (
    config: HistoryFilters & ICommonParams,
  ): Promise<{ data: Operation[]; totalPages: number }> =>
    new Promise((resolve) => {
      OperationsService.operations(
        {
          from: getUTCTime(config.dateFrom),
          to: getUTCTime(config.dateTo),
          symbol: config.currency === 'all' ? undefined : toLowerCase(config.currency),
          type: config.action === 'all' ? undefined : config.action,
          deal_state:
            config.action == 'deal' && config.dealState !== 'all'
              ? config.dealState
              : undefined,
          page: config.page,
          offset: config.offset,
          limit: config.limit,
        },
        (response) => {
          const operations: Operation[] = [];
          if (response && response.data.length) {
            for (const o of response.data) {
              operations.push({
                id: o.id.trim(),
                date: parseDate(o.created_at),
                amount: o.amount,
                amountCurrency: o.amount_currency,
                currency: toUpperCase(o.symbol) || '',
                fiatCurrency: o.currency ? toUpperCase(o.currency) : '',
                action: o.action,
                state: o.state,
              });
            }
          }
          resolve({ data: operations, totalPages: response.total_pages });
        },
      );
    });

  // OK
  static operationsExport = async (
    params: IUserOperationsExportParams,
    onSuccess?: ISuccessCallbackType<IUserOperationsExportData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IUserOperationsExportData>({
      url: '/operations/export',
      params: {
        _from: params.from,
        _to: params.to,
        symbol: params.symbol,
        deal_state: params.dealState,
        action: params.type ? operationTypes[params.type] : undefined,
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static dealsExport = async (
    params: IUserDealsExportParams,
    onSuccess?: ISuccessCallbackType<IUserDealsExportData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IUserOperationsExportData>({
      url: '/deals/export',
      params: {
        _from: params.from,
        _to: params.to,
        symbol: params.symbol,
        currency: params.currency,
        lot_type: params.dealType,
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static referrals = async (
    onSuccess?: ISuccessCallbackType<IUserReferralsData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IUserReferralsData>({
      url: '/operations/affiliates',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static notifications = async (
    params: IUserNotificationsParams,
    onSuccess?: ISuccessCallbackType<IUserNotificationsData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IUserNotificationsData>({
      url: '/operations/updates',
      params: params,
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static allNotifications = async (
    params: IUserAllNotificationsParams,
    onSuccess?: ISuccessCallbackType<IUserAllNotificationsData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IUserAllNotificationsData>({
      url: '/operations/updates',
      params: {
        _from: params.from,
        _to: params.to,
        op_type: params.notificationType,
        limit: params.limit,
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static readNotifications = async (
    params: IUserReadNotificationsParams,
    onSuccess?: ISuccessCallbackType<IUserReadNotificationsData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IUserReadNotificationsData>({
      url: '/operations/updates',
      method: 'PATCH',
      data: { ...params, is_read: true },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static dealLastRequisites = async (
    params: IDealsLastRequisitesParams,
    onSuccess?: ISuccessCallbackType<IDealsLastRequisitesData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IDealsLastRequisitesData>({
      url: '/operations/last-requisites',
      params: { broker: params.paymentMethod },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static fastDealsNotifications = async (
    params: IFastDealNotificationsParams,
    onSuccess?: ISuccessCallbackType<IFastDealNotificationsData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IFastDealNotificationsData>({
      url: '/operations/updates',
      headers: getAuthHeader(params.token),
      params: { limit: params.limit },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static fastDealsReadNotifications = async (
    params: IFastDealReadNotificationsParams,
    onSuccess?: ISuccessCallbackType<IFastDealReadNotificationsData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IFastDealNotificationsData>({
      url: '/operations/updates',
      method: 'PATCH',
      headers: getAuthHeader(params.token),
      data: { ids: params.ids, is_read: true },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static skyPayOperations = async (
    params: ISkyPayOperationsParams,
    onSuccess?: ISuccessCallbackType<ISkyPayOperationsData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<ISkyPayOperationsData>({
      url: '/operations',
      params: {
        _from: params.from,
        _to: params.to,
        symbol: params.symbol,
        currency: params.subCurrency,
        ...(!!params.id && { id: params.id }),
        action: operationTypes[params.operationType],
        op_type: 2,
        page: params.page,
        offset: params.offset,
        limit: params.limit,
        label: params.label,
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static mappedSkyPayOperations = async (config: MerchantFilters & ICommonParams) =>
    new Promise<{ data: MerchantOperation[]; totalPages: number }>((resolve) =>
      OperationsService.skyPayOperations(
        {
          from: getUTCTime(config.dateFrom),
          to: getUTCTime(config.dateTo),
          symbol: config.currency === 'all' ? undefined : toLowerCase(config.currency),
          subCurrency:
            config.subCurrency === 'all' ? undefined : toLowerCase(config.subCurrency),
          operationType: config.operationType,
          id: config.id,
          page: config.page,
          offset: config.offset,
          limit: config.limit,
          label: config.label,
        },
        (response) => {
          if (response) {
            const newOperationsState: MerchantOperation[] = [];
            for (const o of response.data) {
              newOperationsState.push({
                id: o.id,
                date: parseDate(o?.created_at),
                amount: o?.amount,
                amountCurrency: o?.amount_currency,
                label: o?.label,
                commission: o?.commission,
                symbol: o?.symbol?.toUpperCase() || '',
                currency: o?.currency?.toUpperCase() || '',
                target: o?.action,
              });
            }
            resolve({ data: newOperationsState, totalPages: response.total_pages });
          }
        },
      ),
    );

  // OK
  static skyPayOperationsExport = async (
    params: ISkyPayOperationsExportParams,
    onSuccess?: ISuccessCallbackType<ISkyPayOperationsExportData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<ISkyPayOperationsExportData>({
      url: '/operations/export',
      params: {
        _from: params.from,
        _to: params.to,
        symbol: params.symbol,
        currency: params.subCurrency,
        id: params.id,
        action: operationTypes[params.operationType],
        op_type: 2,
        label: params.label,
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static paymentOperationsExport = async (
    params: IPaymentOperationsExportParams,
    onSuccess?: ISuccessCallbackType<ISkyPayOperationsExportData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IPaymentOperationsExportData>({
      url: '/payments/export',
      params: {
        year: params.year,
        month: params.month,
        symbol: params.symbol,
        currency: params.currency,
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });
}
