import debounce from 'lodash/debounce';
import { action, computed, makeAutoObservable, observable, toJS } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

import { Bid } from '../components/bids/BidCard';
import { IDealsFilter } from '../components/deals';
import { HistoryFilters } from '../components/history';
import { AuthorizationDataRow } from '../components/maintenance/AuthorizationData';
import { Operation } from '../components/operations/OperationsList';
import { P2PFilters, P2PRow } from '../components/p2p';
import { MerchantFilters, MerchantV2Filters } from '../components/skypay';
import { Operation as MerchantOperation } from '../components/skypay/OperationsList';
import { ExchangeHistory } from '../domain/ExchangeHistory';
import { DEFAULT_LIMIT, getUserCurrency } from '../helpers/settings';
import { isFunction } from '../helpers/utils';
import DealsService from '../services/DealsService';
import ExchangesService from '../services/ExchangesService';
import LotsService from '../services/LotsService';
import MerchantService from '../services/MerchantService';
import OperationsService from '../services/OperationsService';
import UsersService from '../services/UsersService';
import { DealShort } from '../utils/types';
import { CurrencyStore } from './Currency';

interface BidsFilters {
  type: string;
  currency: string;
  subCurrency: string;
}

type SetCallback<T> = (currentData: T) => T;

class TableStore<D> {
  @observable.deep private _collection: D[];

  constructor(initialData: D[] = []) {
    makeAutoObservable(this);
    this._collection = initialData;
  }

  get data() {
    return this._collection.slice();
  }

  @action setData = (data: D[] | SetCallback<D[]>): void => {
    this._collection = isFunction(data)
      ? (data as SetCallback<D[]>)(this._collection)
      : (data as D[]);
  };
}

class FilterableTableStore<D, T> {
  @observable.deep private _collection: D[];
  @observable private _filters: T;
  private _prevFiatCurrency = '';

  constructor({
    initialData = [],
    initialFilters,
    localStoragePropName,
  }: {
    initialData: D[];
    initialFilters: T;
    localStoragePropName: string;
  }) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: localStoragePropName,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      properties: ['_filters', '_prevFiatCurrency'],
      storage: window.localStorage,
    });
    this._filters = initialFilters;
    this._collection = initialData;
  }

  @computed get filters() {
    if (
      (this._prevFiatCurrency !== CurrencyStore.fiatCurrency ||
        !this._prevFiatCurrency) &&
      !!CurrencyStore.fiatCurrency
    ) {
      this._prevFiatCurrency = CurrencyStore.fiatCurrency;
      this._filters['subCurrency'] = CurrencyStore.fiatCurrency;
    }
    return { ...this._filters };
  }

  get data() {
    return this._collection.slice();
  }

  @action setData = (data: D[] | SetCallback<D[]>): void => {
    this._collection = isFunction(data)
      ? (data as SetCallback<D[]>)(this._collection)
      : (data as D[]);
  };

  @action setFilters = (filters: T | SetCallback<T>): void => {
    this._filters = isFunction(filters)
      ? (filters as SetCallback<T>)(this._filters)
      : (filters as T);
  };

  async clearPersistedData() {
    await clearPersistedStore(this);
  }
}

class PaginatedFilterableTableStore<D, T> {
  @observable.deep private _collection: D[];
  @observable private _filters: T;
  @observable.deep private _other: any;
  @observable.deep private _pagination = {
    currentPage: 1,
    totalPages: 1,
    limit: 10,
    onPageChange: () => null,
    onLoadMore: () => null,
  };
  @observable private loading = false;

  private _prevFiatCurrency = '';
  private _isShowMore = false;
  private fetch: (props: any) => Promise<{ data: D[]; totalPages: number } & any>;
  private delayedFetch: (props: any) => Promise<{ data: D[]; totalPages: number } & any>;

  constructor({
    initialFilters,
    localStoragePropName,
    fetchFunction,
    defaultLimit,
  }: {
    initialFilters: T;
    localStoragePropName?: string;
    fetchFunction: (props: any) => Promise<{ data: D[]; totalPages: number } & any>;
    defaultLimit?: number;
    isShowMore?: boolean;
  }) {
    makeAutoObservable(this);
    localStoragePropName &&
      makePersistable(this, {
        name: localStoragePropName,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        properties: ['_filters', '_prevFiatCurrency'],
        storage: window.localStorage,
      });
    this._collection = [];
    this._filters = initialFilters;
    this._other = {};
    this._pagination.limit = defaultLimit ?? 10;
    this.fetch = fetchFunction;
    this.delayedFetch = debounce(fetchFunction);
  }

  @computed get filters() {
    if (
      (this._prevFiatCurrency !== CurrencyStore.fiatCurrency ||
        !this._prevFiatCurrency) &&
      CurrencyStore.fiatCurrency
    ) {
      this._prevFiatCurrency = CurrencyStore.fiatCurrency;
      this._filters['subCurrency'] = CurrencyStore.fiatCurrency;
      this.fetchFunction();
    }
    return { ...this._filters };
  }

  get data() {
    return this._collection.slice();
  }

  get pagination() {
    return {
      ...this._pagination,
      onPageChange: this.onPageChange,
      onLoadMore: this.onLoadMore,
    };
  }

  get isLoading() {
    return this.loading;
  }

  get other() {
    return toJS(this._other);
  }

  get fetchFunction() {
    return (mapper?: <J = D>(item: D) => J) => {
      this.loading = true;
      return this.fetch({
        ...this._filters,
        limit: this._pagination.limit,
        page: this._isShowMore
          ? this._pagination.currentPage + 1
          : this._pagination.currentPage,
        offset: 0,
      })
        .then((response) => {
          const { data, ...other } = response;
          const newData = data.map((record) => mapper?.(record) ?? record);
          this._pagination.totalPages = other.totalPages;
          this._other = other;
          if (this._isShowMore) {
            this._pagination.currentPage++;
            this._isShowMore = false;
            this._collection = [...this._collection, ...newData];
            return response;
          }
          this._collection = newData;
          return response;
        })
        .finally(() => (this.loading = false));
    };
  }

  @action onPageChange = (page: number | string, isShowMore = false) => {
    if (!isShowMore) {
      this._pagination.currentPage = +page;
    }
    this._isShowMore = isShowMore;
    this.fetchFunction();
  };

  @action onLoadMore = () => {
    if (this._pagination.currentPage !== this._pagination.totalPages) {
      this.onPageChange(this._pagination.currentPage + 1, true);
    }
  };

  @action setData = (data: D[] | SetCallback<D[]>): void => {
    this._collection = isFunction(data)
      ? (data as SetCallback<D[]>)(this._collection)
      : (data as D[]);
  };

  private debouncedFetch = debounce(this.fetchFunction, 500);

  @action setFilters = (filters: T | SetCallback<T>, request?: () => void): void => {
    this._pagination.currentPage = 1;
    this._filters = isFunction(filters)
      ? (filters as SetCallback<T>)(this._filters)
      : (filters as T);
    request ? request() : this.debouncedFetch();
  };

  @action setLimit = (limit: number): void => {
    this._pagination.limit = limit;
  };

  @action setPage = (page: number): void => {
    this._pagination.currentPage = page;
  };

  @action resetPage = (): void => {
    this._pagination.currentPage = 1;
  };

  async clearPersistedData() {
    await clearPersistedStore(this);
  }
}

class NextPrevFilterableTableStore<D, T> {
  @observable.deep private _collection: D[];
  @observable private _filters: T;
  @observable.deep private _pagination = {
    currentPage: 1,
    totalPages: 1,
    limit: 10,
    onPageChange: () => null,
    onLoadMore: () => null,
    isNextPrev: true,
    nextWillBeDisabled: false,
  };
  @observable private loading = false;

  private _prevFiatCurrency = '';
  private fetch: (props: any) => Promise<{ data: D[]; totalPages: number } & any>;

  constructor({
    initialFilters,
    localStoragePropName,
    fetchFunction,
    defaultLimit,
  }: {
    initialFilters: T;
    localStoragePropName?: string;
    fetchFunction: (props: any) => Promise<{ data: D[]; totalPages: number } & any>;
    defaultLimit?: number;
    isShowMore?: boolean;
  }) {
    makeAutoObservable(this);
    localStoragePropName &&
      makePersistable(this, {
        name: localStoragePropName,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        properties: ['_filters', '_prevFiatCurrency'],
        storage: window.localStorage,
      });
    this._collection = [];
    this._filters = initialFilters;
    this._pagination.limit = defaultLimit ?? 10;
    this.fetch = fetchFunction;
  }

  @computed get filters() {
    if (
      (this._prevFiatCurrency !== CurrencyStore.fiatCurrency ||
        !this._prevFiatCurrency) &&
      CurrencyStore.fiatCurrency
    ) {
      this._prevFiatCurrency = CurrencyStore.fiatCurrency;
      this._filters['subCurrency'] = CurrencyStore.fiatCurrency;
      this.fetchFunction();
    }
    return { ...this._filters };
  }

  get data() {
    return this._collection.slice();
  }

  get pagination() {
    return {
      ...this._pagination,
      onPageChange: this.onPageChange,
    };
  }

  get isLoading() {
    return this.loading;
  }

  get fetchFunction() {
    return (mapper?: <J = D>(item: D) => J) => {
      this.loading = true;
      return this.fetch({
        ...this._filters,
        limit: this._pagination.limit,
        offset: (this._pagination.currentPage - 1) * this._pagination.limit,
      })
        .then((response) => {
          const newData = response.map((record) => mapper?.(record) ?? record);
          this._collection = newData;
          return response;
        })
        .then(() => {
          return this.fetch({
            ...this._filters,
            limit: this._pagination.limit,
            offset: this._pagination.currentPage * this._pagination.limit,
          });
        })
        .then((response) => {
          this._pagination.nextWillBeDisabled = response.length < this._pagination.limit;
        })
        .finally(() => (this.loading = false));
    };
  }

  @action onPageChange = (direction: 'next' | 'prev') => {
    this._pagination.currentPage =
      this._pagination.currentPage + (direction === 'next' ? 1 : -1);
    this.fetchFunction();
  };

  @action setData = (data: D[] | SetCallback<D[]>): void => {
    this._collection = isFunction(data)
      ? (data as SetCallback<D[]>)(this._collection)
      : (data as D[]);
  };

  @action setFilters = (filters: T | SetCallback<T>, request?: () => void): void => {
    this._pagination.currentPage = 1;
    this._pagination.nextWillBeDisabled = false;
    this._filters = isFunction(filters)
      ? (filters as SetCallback<T>)(this._filters)
      : (filters as T);
    request ? request() : this.fetchFunction();
  };

  @action setLimit = (limit: number): void => {
    this._pagination.limit = limit;
  };

  @action setPage = (page: number): void => {
    this._pagination.currentPage = page;
  };

  @action resetPage = (): void => {
    this._pagination.currentPage = 1;
  };

  async clearPersistedData() {
    await clearPersistedStore(this);
  }
}

export const P2PTableStore = new PaginatedFilterableTableStore<P2PRow, P2PFilters>({
  initialFilters: {
    currency: 'BTC',
    bidType: 'purchase',
    paymentMethod: { id: '', name: 'all' },
    subCurrency: CurrencyStore.fiatCurrency || getUserCurrency(),
  },
  localStoragePropName: 'P2P_filters',
  fetchFunction: LotsService.marketP2PRows,
  defaultLimit: 25,
});

export const AuthorizationDataTableStore = new PaginatedFilterableTableStore<
  AuthorizationDataRow,
  P2PFilters
>({
  initialFilters: {
    currency: 'BTC',
    bidType: 'purchase',
    paymentMethod: { id: '', name: 'all' },
    subCurrency: CurrencyStore.fiatCurrency || getUserCurrency(),
  },
  fetchFunction: UsersService.loginUserData,
  defaultLimit: DEFAULT_LIMIT,
});

export const WalletOperationsTableStore = new PaginatedFilterableTableStore<
  Operation,
  HistoryFilters
>({
  initialFilters: {
    dateFrom: undefined,
    dateTo: undefined,
    action: 'all',
    currency: 'all',
  },
  fetchFunction: OperationsService.walletOperations,
});

export const DealsTableStore = new NextPrevFilterableTableStore<DealShort, IDealsFilter>({
  initialFilters: {
    dealType: 'all',
    currency: 'BTC',
    subCurrency: CurrencyStore.fiatCurrency || getUserCurrency(),
  },
  localStoragePropName: 'deals_filters',
  fetchFunction: DealsService.dealsShortList,
});

export const MyBidsStore = new PaginatedFilterableTableStore<Bid, BidsFilters>({
  initialFilters: {
    type: 'all',
    currency: 'all',
    subCurrency: CurrencyStore.fiatCurrency || getUserCurrency(),
  },
  localStoragePropName: 'bids_filters',
  defaultLimit: DEFAULT_LIMIT,
  fetchFunction: LotsService.bidsShortList,
});

export const HistoryTableStore = new PaginatedFilterableTableStore<
  Operation,
  HistoryFilters
>({
  initialFilters: {
    dateFrom: undefined,
    dateTo: undefined,
    action: 'all',
    currency: 'all',
    dealState: 'all',
  },
  localStoragePropName: 'history_filters',
  fetchFunction: OperationsService.walletOperations,
});

export const ExchangeHistoryTableStore = new PaginatedFilterableTableStore<
  ExchangeHistory,
  Record<string, never>
>({ initialFilters: {}, fetchFunction: ExchangesService.mappedExchangeHistory });

export const MerchantHistoryTableStore = new PaginatedFilterableTableStore<
  MerchantOperation,
  MerchantFilters
>({
  initialFilters: {
    dateFrom: undefined,
    dateTo: undefined,
    operationType: 'sky pay',
    currency: 'all',
    subCurrency: 'all',
    id: '',
    label: '',
  },
  localStoragePropName: 'merchant_history_filters',
  fetchFunction: OperationsService.mappedSkyPayOperations,
});

export const MerchantHistoryV2TableStore = new NextPrevFilterableTableStore<
  MerchantOperation,
  MerchantV2Filters
>({
  initialFilters: {
    dateFrom: (() => {
      const start = new Date();
      start.setHours(0, 0, 0, 0);
      return start;
    })(),
    dateTo: undefined,
    operationType: 'pay',
    currency: 'all',
    subCurrency: 'all',
    id: '',
    label: '',
  },
  fetchFunction: MerchantService.mappedMerchantOperations,
});

export const BidsGridStore = new FilterableTableStore<Bid, BidsFilters>({
  initialData: [],
  initialFilters: {
    type: 'all',
    currency: 'all',
    subCurrency: CurrencyStore.fiatCurrency || getUserCurrency(),
  },
  localStoragePropName: 'bids_filters',
});

export const UserActiveBidsStore = new PaginatedFilterableTableStore<
  Bid,
  { user: string }
>({
  initialFilters: {
    user: '',
  },
  localStoragePropName: 'user_bids_filters',
  fetchFunction: LotsService.userActiveBids,
  defaultLimit: 25,
});

export const deletePersistedData = () => {
  P2PTableStore.clearPersistedData();
  DealsTableStore.clearPersistedData();
  HistoryTableStore.clearPersistedData();
  MerchantHistoryTableStore.clearPersistedData();
  BidsGridStore.clearPersistedData();
  AuthorizationDataTableStore.clearPersistedData();
  MerchantHistoryV2TableStore.clearPersistedData();
};
